import React from 'react'
import c from 'classnames';

import anotacoesSrc from 'assets/images/anotacoes_fh.png';
import helpers from 'styles/_helpers.module.scss';
import styles from 'styles/pages/authors.module.scss';
import Layout from 'components/layout';
import FiltroAutores from 'components/FiltroAutores';



const Autores = ({ location }) => (
  <Layout location={location} title="Autores">
    <div className={c(styles.grid_anotacoes, helpers.pt120)}>
      <img src={anotacoesSrc} className={c(helpers.fw, styles.anotacoes_img)} alt="" />
      <div>
        <h1 className={helpers.mb24}>nossos autores</h1>
        <h1 className={styles.entry_title}>
          {String.fromCharCode(160)}  Vários artigos do Anotado incluem {String.fromCharCode(160)} <br /> {String.fromCharCode(160)} anotações e comentários feitos por {String.fromCharCode(160)} <br /> {String.fromCharCode(160)}  renomeados processualistas.{String.fromCharCode(160)}
        </h1>
      </div>
    </div>

    <FiltroAutores />

  </Layout>
);

export default Autores;
