import React from 'react'
import c from 'classnames';

import autores from 'assets/authors.json';
import groupBy from 'lodash/groupBy';
import debounce from 'lodash/debounce';
import helpers from 'styles/_helpers.module.scss';
import wrappers from 'styles/_wrappers.module.scss';
import styles from 'styles/pages/authors.module.scss';

const escapeString = (s) => s.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');

export default class FiltroAutores extends React.PureComponent {
  state = {
    filtro: '',
    authors: groupBy(autores, e => e.name[0])
  };

  handleFilterClick = () => {
    const { filtro } = this.state;
    const f = escapeString(filtro).toLowerCase();
    const filteredAuthors = autores.filter(a => a.name.toLowerCase().match(f))
    this.setState({ authors: groupBy(filteredAuthors, e => e.name[0]) });
  };

  handleOnFiltroChange = filtro => this.setState({ filtro });

  debouncedChange = debounce(this.handleOnFiltroChange, 150);

  keyPress = e => {
    if(e.keyCode === 13){
      this.handleFilterClick();
    }
  }

  render() {
    const { authors } = this.state;

    return (
      <React.Fragment>
        <div className={c(helpers.fw, helpers.t_center, helpers.mt24)}>
          <input
            onKeyDown={this.keyPress}
            onChange={e => this.debouncedChange(e.target.value)}
            type="text" className={styles.filter_input}
            placeholder="Nome"
          />
          <button
            onClick={this.handleFilterClick}
            className={c(helpers.mt24, helpers.ml8,styles.btn)}
          >
            filtrar autores
          </button>
        </div>

        {Object.keys(authors).map((key, i) => (
          <React.Fragment key={i}>
            <div className={styles.auth_list_wrapper}>
              <h3 className={c(helpers.mb24, helpers.mt24)}><b>{key}</b></h3>
            </div>
            <div>
              <div key={i}>
                {authors[key].map((e, i) => (
                  <div className={styles.auth_list_wrapper}>
                    <p className={styles.auth_name}>{e.name}</p>
                    <p className={styles.auth_code}>{e.apps.join(', ')}</p>
                    <a href={e.url} className={styles.auth_site}>ver currículo</a>
                  </div>
                ))}
              </div>
            </div>
          </React.Fragment>
        ))}
      </React.Fragment>
    );
  }
}
